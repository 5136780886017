import { ShipmentsStatus } from '../TrackerSearchBar/TrackerSearchBarModels';

export interface TrackerOutputProps {
  key: number;
  componentData: ShipmentsStatus;
}
export interface TrackingDetail {
  status: TrackingStatus;
  statusDescription?: string;
  timestamp: string;
}
export enum TrackingStatus {
  READY_TO_DISPATCH = 'READY-TO-DISPATCH',
  DISPATCHED = 'DISPATCHED',
  RECEIVED_BY_CARRIER = 'RECEIVED-BY-CARRIER',
  CANCELLED = 'CANCELLED',
  IN_TRANSIT = 'IN-TRANSIT',
  OUT_FOR_DELIVERY = 'OUT-FOR-DELIVERY',
  DELIVERED = 'DELIVERED',
  EXCEPTIONS = 'EXCEPTIONS',
  NOT_FOUND = 'NOT-FOUND',
  TO_BE_IGNORED = 'TO-BE-IGNORED',
}

export interface TrackingUiDetail {
  status: string;
  localTime: string;
  isLatestStatus?: boolean;
  isException?: boolean;
  icon: string;
  isSet: boolean;
}

export enum TrackerOutputTranslationElementId {
  card_tracking_number_header = 'card_tracking_number_header',
  carrier_name = 'carrier_name',
  deliver_to = 'deliver_to',
  estimated_delivery = 'estimated_delivery',
}

export const Icon = {
  checkMark: '✓',
};
