import React from 'react'
import * as ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import * as tisProvider from './services/tis-proxy-service'
import { createStore, Reducer, AnyAction } from 'redux'
import { Languages } from './translations/translations'
import { Provider } from 'react-redux'

// Get the default stored language as per previous website access OR
// Get the browser language OR
// Default to English
const language: Languages =
  (localStorage.getItem('language') as Languages) ||
  (navigator.language.split(/[-]/)[0] as Languages) ||
  Languages.en

const initialState = {
  language,
}
// The reducer function is invoked whenever there is a change in the global state
export const reducer: Reducer<object, AnyAction> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return {
        language: action.language,
      }
    default:
      return initialState
  }
}
// Create the global state store for redux
const store = createStore(reducer)
;(async () => {
  // Initialize a token for the proxy to facilitate TIS transactions.
  await tisProvider.initToken()
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
})()
