/**
 * @fileoverview Stores Dutch translations for component texts.
 * ISO Language Name: Dutch
 * ISO 639-1 Language Code: nl
 * Native Name: Nederlands, Vlaams
 */

import {
  TranslationMessage,
  TrackingStatusTranslationId,
  TrackingStatusExtendedDescriptionId,
} from '../../models';
import { NavbarTranslationElementId } from '../../components/Navbar/NavbarModels';
import { TrackerSearchElementId } from '../../components/TrackerSearchBar/TrackerSearchBarModels';
import { TrackerOutputTranslationElementId } from '../../components/TrackerOutput/TrackerOutputModels';
import { FooterTranslationElementId } from '../../components/Footer/FooterModels';

const nlTranslations: TranslationMessage = {
  [NavbarTranslationElementId.header]: 'Verzending volgen',
  [NavbarTranslationElementId.menu]: 'Menu',
  [NavbarTranslationElementId.language]: 'Taal',

  [TrackerSearchElementId.track_button]: 'Zoek',
  [TrackerSearchElementId.track_textfield_placeholder]: `Voer volgnummer(s) in (door komma's gescheiden)`,

  [TrackerOutputTranslationElementId.card_tracking_number_header]:
    'Volg Nummer',
  [TrackerOutputTranslationElementId.carrier_name]: 'Verzonden Met',
  [TrackerOutputTranslationElementId.deliver_to]: 'Verzend naar',
  [TrackerOutputTranslationElementId.estimated_delivery]: 'Geschatte levering',

  [FooterTranslationElementId.copyright_notice_message]:
    'Alle rechten voorbehouden',
  [FooterTranslationElementId.timezone_notice_message]:
    'Alle getoonde datums en tijden zijn',

  [TrackingStatusTranslationId.delivered]: 'Geleverd',
  [TrackingStatusTranslationId.dispatched]: 'Verzonden',
  [TrackingStatusTranslationId.in_transit]: 'Onderweg',
  [TrackingStatusTranslationId.out_for_delivery]: 'Uit voor levering',
  [TrackingStatusTranslationId.ready_to_dispatch]: 'Klaar voor verzending',
  [TrackingStatusTranslationId.cancelled]: 'Geannuleerd',
  [TrackingStatusTranslationId.received_by_carrier]:
    'Ontvangen door vervoerder',

  [TrackingStatusExtendedDescriptionId.delivered]: 'Zending afgeleverd',
  [TrackingStatusExtendedDescriptionId.exception]: 'Er is iets fout gegaan!',
  [TrackingStatusExtendedDescriptionId.cancelled]: 'De zending is geannuleerd',
  [TrackingStatusExtendedDescriptionId.in_transit]:
    'De koerier is bezig met het bezorgen van de zending',
  [TrackingStatusExtendedDescriptionId.out_for_delivery]:
    'Binnenkort aankomen!',
  [TrackingStatusExtendedDescriptionId.prepared]: 'Verzending voorbereid',
  [TrackingStatusExtendedDescriptionId.received_by_carrier]:
    'Zending ontvangen door de koerier',
  [TrackingStatusExtendedDescriptionId.not_found]: 'Trackingnummer is niet gevonden. Als uw zending vandaag is aangemaakt, probeer het dan later nog eens.',
};

export default nlTranslations;
