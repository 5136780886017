import { v4 as uuid } from 'uuid';
import axios from 'axios';

const PROXY_ENDPOINT = 'https://proxy.so.cimpress.io';
const TIS_API_ID = 'Jo3pR4S';

/**
 * Calls the proxy service to get a temporary token to be able to call proxy to query
 * statuses of tracking numbers.
 * @param {string} trackingNumber Comma separated list of tracking numbers
 * @public
 */
export async function getTrackInformation(trackingNumber: string) {
  const token = await getRequestToken();

  const result = await axios.get(
    `${PROXY_ENDPOINT}/api/v1/shipments-status?trackingNumbers=${trackingNumber.replace(
      / /g,
      ''
    )}`,
    {
      headers: {
        token,
        'api-id': TIS_API_ID,
      },
    }
  );

  return result.data;
}

/**
 * Initializes the token and sets in local storage
 * @public
 */
export async function initToken() {
  let token = uuid();

  await axios.post(`${PROXY_ENDPOINT}/api/v1/token`, {
    token,
  });

  localStorage.setItem('token', token);
}

export async function getRequestToken() {
  let token = localStorage.getItem('token');

  const response = await axios.get(`${PROXY_ENDPOINT}/api/v1/token`, {
    headers: {
      token,
    },
  });
  localStorage.setItem('token', response.data.token);

  return response.data.token;
}
