/**
 * @fileoverview Stores English translations for component texts.
 * ISO Language Name: English
 * ISO 639-1 Language Code: en
 * Native Name: English
 */

import {
  TranslationMessage,
  TrackingStatusTranslationId,
  TrackingStatusExtendedDescriptionId,
} from '../../models';
import { NavbarTranslationElementId } from '../../components/Navbar/NavbarModels';
import { TrackerSearchElementId } from '../../components/TrackerSearchBar/TrackerSearchBarModels';
import { TrackerOutputTranslationElementId } from '../../components/TrackerOutput/TrackerOutputModels';
import { FooterTranslationElementId } from '../../components/Footer/FooterModels';

const enTranslations: TranslationMessage = {
  [NavbarTranslationElementId.header]: 'Shipment Tracking',
  [NavbarTranslationElementId.menu]: 'Menu',
  [NavbarTranslationElementId.language]: 'Language',

  [TrackerSearchElementId.track_button]: 'Track',
  [TrackerSearchElementId.track_textfield_placeholder]: `Enter tracking number(s) (Comma separated)`,

  [TrackerOutputTranslationElementId.card_tracking_number_header]:
    'Tracking Number',
  [TrackerOutputTranslationElementId.carrier_name]: 'Shipped With',
  [TrackerOutputTranslationElementId.deliver_to]: 'Deliver To',

  [TrackerOutputTranslationElementId.estimated_delivery]: 'Estimated Delivery',

  [FooterTranslationElementId.copyright_notice_message]: 'All Rights Reserved',
  [FooterTranslationElementId.timezone_notice_message]:
    'All dates and times shown are',

  [TrackingStatusTranslationId.delivered]: 'Delivered',
  [TrackingStatusTranslationId.dispatched]: 'Dispatched',
  [TrackingStatusTranslationId.in_transit]: 'In Transit',
  [TrackingStatusTranslationId.out_for_delivery]: 'Out For Delivery',
  [TrackingStatusTranslationId.ready_to_dispatch]: 'Ready To Dispatch',
  [TrackingStatusTranslationId.received_by_carrier]: 'Received By Carrier',
  [TrackingStatusTranslationId.cancelled]: 'Cancelled',

  [TrackingStatusExtendedDescriptionId.delivered]: 'Shipment delivered',
  [TrackingStatusExtendedDescriptionId.exception]: 'Something went wrong!',
  [TrackingStatusExtendedDescriptionId.in_transit]:
    'The carrier is in process of delivering the shipment',
  [TrackingStatusExtendedDescriptionId.out_for_delivery]: 'Arriving soon!',
  [TrackingStatusExtendedDescriptionId.prepared]: 'Shipment prepared',
  [TrackingStatusExtendedDescriptionId.cancelled]: 'The shipment is cancelled',
  [TrackingStatusExtendedDescriptionId.received_by_carrier]:
    'Shipment received by the carrier',
  [TrackingStatusExtendedDescriptionId.not_found]: 'Tracking Number was not found. If your shipment was created today, please try again in sometime.',
};

export default enTranslations;
