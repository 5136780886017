/**
 * @fileoverview Stores Hindi translations for component texts.
 * ISO Language Name: Hindi
 * ISO 639-1 Language Code: hi
 * Native Name: Hindi (हिंदी)
 */

import {
  TranslationMessage,
  TrackingStatusTranslationId,
  TrackingStatusExtendedDescriptionId,
} from '../../models';
import { NavbarTranslationElementId } from '../../components/Navbar/NavbarModels';
import { TrackerSearchElementId } from '../../components/TrackerSearchBar/TrackerSearchBarModels';
import { TrackerOutputTranslationElementId } from '../../components/TrackerOutput/TrackerOutputModels';
import { FooterTranslationElementId } from '../../components/Footer/FooterModels';

const hiTranslations: TranslationMessage = {
  [NavbarTranslationElementId.header]: 'शिपमेंट ट्रैकिंग',
  [NavbarTranslationElementId.menu]: 'मेन्यू',
  [NavbarTranslationElementId.language]: 'भाषा',

  [TrackerSearchElementId.track_button]: 'खोजिए',
  [TrackerSearchElementId.track_textfield_placeholder]: `ट्रैकिंग नंबर दर्ज करें (कोमा अलग)`,

  [TrackerOutputTranslationElementId.card_tracking_number_header]: 'खोज संख्या',
  [TrackerOutputTranslationElementId.carrier_name]: 'वाहक का नाम',
  [TrackerOutputTranslationElementId.deliver_to]: 'यहां भेजें',
  [TrackerOutputTranslationElementId.estimated_delivery]: 'अनुमानित डिलिवरी',

  [FooterTranslationElementId.copyright_notice_message]: 'सर्वाधिकार सुरक्षित',
  [FooterTranslationElementId.timezone_notice_message]:
    'दिखाए गए सभी दिनांक और समय हैं',

  [TrackingStatusTranslationId.delivered]: 'पहुंचा दिया',
  [TrackingStatusTranslationId.cancelled]: 'रद्द',
  [TrackingStatusTranslationId.dispatched]: 'भेजा',
  [TrackingStatusTranslationId.in_transit]: 'रास्ते में',
  [TrackingStatusTranslationId.out_for_delivery]: 'डिलिवरी के लिए रवाना',
  [TrackingStatusTranslationId.ready_to_dispatch]: 'डिस्पैच के लिए तैयार',
  [TrackingStatusTranslationId.received_by_carrier]:
    'कैरियर द्वारा प्राप्त किया',

  [TrackingStatusExtendedDescriptionId.delivered]: 'शिपमेंट पहुंच गया',
  [TrackingStatusExtendedDescriptionId.exception]: 'कुछ गलत हो गया!',
  [TrackingStatusExtendedDescriptionId.in_transit]:
    'वाहक शिपमेंट पहुंचाने की प्रक्रिया में है',
  [TrackingStatusExtendedDescriptionId.out_for_delivery]: 'जल्द ही आ रहा है!',
  [TrackingStatusExtendedDescriptionId.prepared]: 'शिपमेंट तैयार किया गया',
  [TrackingStatusExtendedDescriptionId.received_by_carrier]:
    'वाहक द्वारा प्राप्त शिपमेंट',
  [TrackingStatusExtendedDescriptionId.not_found]: 'ट्रैकिंग नंबर नहीं मिला। यदि आपकी शिपमेंट आज ही बनाई गई थी, तो कृपया कुछ समय बाद पुनः प्रयास करें।',
  [TrackingStatusExtendedDescriptionId.cancelled]:
    'शिपमेंट रद्द कर दिया गया है',
};

export default hiTranslations;
