import { TranslationMessage } from '../models';
import hiTranslations from './languages/hi';
import nlTranslations from './languages/nl';
import itTranslations from './languages/it';
import enTranslations from './languages/en';
import deTranslations from './languages/de';

export enum Languages {
  en = 'en',
  nl = 'nl',
  it = 'it',
  hi = 'hi',
  de = 'de',
}

const translations = new Map<Languages, TranslationMessage>([
  [Languages.hi, hiTranslations],
  [Languages.en, enTranslations],
  [Languages.nl, nlTranslations],
  [Languages.it, itTranslations],
  [Languages.de, deTranslations],
]);

export default translations;

export function getSupportedLanguages() {
  return [
    {
      name: 'English',
      value: Languages.en,
    },
    {
      name: 'Hindi',
      value: Languages.hi,
    },
    {
      name: 'Italian',
      value: Languages.it,
    },
    {
      name: 'German',
      value: Languages.de,
    },
    {
      name: 'Dutch',
      value: Languages.nl,
    },
  ];
}
