/**
 * @fileoverview Stores Italian translations for component texts.
 * ISO Language Name: Italian
 * ISO 639-1 Language Code: it
 * Native Name: Italiano
 */

import {
  TranslationMessage,
  TrackingStatusTranslationId,
  TrackingStatusExtendedDescriptionId,
} from '../../models';
import { NavbarTranslationElementId } from '../../components/Navbar/NavbarModels';
import { TrackerSearchElementId } from '../../components/TrackerSearchBar/TrackerSearchBarModels';
import { TrackerOutputTranslationElementId } from '../../components/TrackerOutput/TrackerOutputModels';
import { FooterTranslationElementId } from '../../components/Footer/FooterModels';

const itTranslations: TranslationMessage = {
  [NavbarTranslationElementId.header]: 'Verzending volgen',
  [NavbarTranslationElementId.menu]: 'Menu',
  [NavbarTranslationElementId.language]: 'Linguaggio',

  [TrackerSearchElementId.track_button]: 'Traccia',
  [TrackerSearchElementId.track_textfield_placeholder]: `Inserisci i numeri di spedizione (separati da virgola)`,

  [TrackerOutputTranslationElementId.card_tracking_number_header]:
    'Numero di spedizione',
  [TrackerOutputTranslationElementId.carrier_name]: 'Spedito Con',
  [TrackerOutputTranslationElementId.deliver_to]: 'Spedire a',
  [TrackerOutputTranslationElementId.estimated_delivery]: 'Consegna stimata',

  [FooterTranslationElementId.copyright_notice_message]:
    'Tutti i diritti riservati',
  [FooterTranslationElementId.timezone_notice_message]:
    'Alle getoonde datums en tijden zijn',

  [TrackingStatusTranslationId.delivered]: 'Consegnato',
  [TrackingStatusTranslationId.dispatched]: 'Inviato',
  [TrackingStatusTranslationId.in_transit]: 'In transito',
  [TrackingStatusTranslationId.out_for_delivery]: 'In consegna',
  [TrackingStatusTranslationId.ready_to_dispatch]: 'Pronto per la spedizione',
  [TrackingStatusTranslationId.received_by_carrier]: 'Ricevuto dal corriere',
  [TrackingStatusTranslationId.cancelled]: 'Annullato',

  [TrackingStatusExtendedDescriptionId.delivered]: 'Consegna effettuata',
  [TrackingStatusExtendedDescriptionId.exception]: 'Qualcosa è andato storto!',
  [TrackingStatusExtendedDescriptionId.cancelled]: 'La spedizione è annullata',
  [TrackingStatusExtendedDescriptionId.in_transit]:
    'Il corriere è in procinto di consegnare la spedizione',
  [TrackingStatusExtendedDescriptionId.out_for_delivery]: 'In arrivo!',
  [TrackingStatusExtendedDescriptionId.prepared]: 'Spedizione preparata',
  [TrackingStatusExtendedDescriptionId.received_by_carrier]:
    'Spedizione ricevuta dal corriere',
  [TrackingStatusExtendedDescriptionId.not_found]: 'Il numero di tracciamento non è stato trovato. Se la tua spedizione è stata creata oggi, riprova tra qualche tempo.',
};

export default itTranslations;
