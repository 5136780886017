import { WrappedComponentProps } from 'react-intl'
import { Languages } from '../../translations/translations'

export enum NavbarTranslationElementId {
  header = 'navbar_app_header',
  menu = 'navbar_app_menu',
  language = 'language',
}

export interface PreferencesProps extends WrappedComponentProps<'intl'> {
  dispatch: (arg: any) => void
  language: Languages
}
