import {
  TrackingStatus,
  TrackerOutputTranslationElementId,
} from './components/TrackerOutput/TrackerOutputModels';
import { FooterTranslationElementId } from './components/Footer/FooterModels';
import { TrackerSearchElementId } from './components/TrackerSearchBar/TrackerSearchBarModels';
import { NavbarTranslationElementId } from './components/Navbar/NavbarModels';

export enum TrackingStatusTranslationId {
  ready_to_dispatch = 'tracking_status_ready_to_dispatch',
  dispatched = 'tracking_status_dispatched',
  received_by_carrier = 'tracking_status_received_by_carrier',
  in_transit = 'tracking_status_in_transit',
  out_for_delivery = 'tracking_status_out_for_delivery',
  delivered = 'tracking_status_delivered',
  cancelled = 'tracking_status_cancelled',
}

export enum TrackingStatusExtendedDescriptionId {
  prepared = 'shipment_prepared',
  delivered = 'shipment_delivered',
  exception = 'shipment_exception',
  in_transit = 'shipment_in_transit',
  out_for_delivery = 'shipment_out_for_delivery',
  received_by_carrier = 'shipment_received_by_carrier',
  not_found = 'shipment_not_found',
  cancelled = 'shipment_cancelled',
}

export interface TranslationMessage {
  [TrackerOutputTranslationElementId.card_tracking_number_header]: string;
  [TrackerOutputTranslationElementId.carrier_name]: string,
  [TrackerOutputTranslationElementId.deliver_to]: string;
  [TrackerOutputTranslationElementId.estimated_delivery]: string;
  [TrackingStatusExtendedDescriptionId.not_found]: string;

  // Homepage Track Body Elements
  [TrackerSearchElementId.track_button]: string;
  [TrackerSearchElementId.track_textfield_placeholder]: string;

  // Navbar Elements
  [NavbarTranslationElementId.header]: string;
  [NavbarTranslationElementId.menu]: string;
  [NavbarTranslationElementId.language]: string;

  // Footer Elements
  [FooterTranslationElementId.copyright_notice_message]: string;
  [FooterTranslationElementId.timezone_notice_message]: string;

  // Tracking Status
  [TrackingStatusTranslationId.ready_to_dispatch]: string;
  [TrackingStatusTranslationId.dispatched]: string;
  [TrackingStatusTranslationId.received_by_carrier]: string;
  [TrackingStatusTranslationId.in_transit]: string;
  [TrackingStatusTranslationId.out_for_delivery]: string;
  [TrackingStatusTranslationId.delivered]: string;
  [TrackingStatusTranslationId.cancelled]: string;

  // Individual description of tracking status
  [TrackingStatusExtendedDescriptionId.delivered]: string;
  [TrackingStatusExtendedDescriptionId.exception]: string;
  [TrackingStatusExtendedDescriptionId.in_transit]: string;
  [TrackingStatusExtendedDescriptionId.out_for_delivery]: string;
  [TrackingStatusExtendedDescriptionId.prepared]: string;
  [TrackingStatusExtendedDescriptionId.received_by_carrier]: string;
  [TrackingStatusExtendedDescriptionId.cancelled]: string;
}

/**
 * Tracking Status Mapped to their respective translation ID
 */
export const STATUS_TRANSLATION_ID = new Map<
  TrackingStatus,
  TrackingStatusTranslationId
>([
  [
    TrackingStatus.READY_TO_DISPATCH,
    TrackingStatusTranslationId.ready_to_dispatch,
  ],
  [TrackingStatus.DELIVERED, TrackingStatusTranslationId.delivered],
  [
    TrackingStatus.RECEIVED_BY_CARRIER,
    TrackingStatusTranslationId.received_by_carrier,
  ],
  [
    TrackingStatus.OUT_FOR_DELIVERY,
    TrackingStatusTranslationId.out_for_delivery,
  ],
  [TrackingStatus.IN_TRANSIT, TrackingStatusTranslationId.in_transit],
  [TrackingStatus.DISPATCHED, TrackingStatusTranslationId.dispatched],
  [TrackingStatus.CANCELLED, TrackingStatusTranslationId.cancelled],
]);
