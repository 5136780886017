import React, { FormEvent } from "react";
import * as cc from "@cimpress/react-components";
import * as tisProvider from "../../services/tis-proxy-service";
import * as qs from "querystring";
import {
  TrackerSearchBarProps,
  TrackerSearchBarState,
  TrackerSearchElementId
} from "./TrackerSearchBarModels";
import TrackerOutput from "../TrackerOutput/TrackerOutput";
import { FormattedMessage, injectIntl } from "react-intl";

let { Spinner } = cc.shapes;

export class TrackerSearchBar extends React.Component<
  TrackerSearchBarProps,
  TrackerSearchBarState
> {
  constructor(props: TrackerSearchBarProps) {
    super(props);
    this.state = {
      trackingNumber: "",
      trackingDetails: {
        shipmentsStatus: []
      },
      spinnerHidden: true,
      isTrackingApiError: false,
      trackingApiError: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.populateTrackingInformation = this.populateTrackingInformation.bind(
      this
    );
  }

  async setStateSync(obj: any) {
    return new Promise(resolve => this.setState(obj, resolve));
  }

  /**
   * This methods gets the tracking details for the specified tracking number(s)
   * and populates them in the web UI.
   *
   * @param {*} event
   */
  async populateTrackingInformation(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.setState({ spinnerHidden: false, isTrackingApiError: false });
    try {
      const trackingDetails = await tisProvider.getTrackInformation(
        this.state.trackingNumber
      );
      this.setState({ trackingDetails, spinnerHidden: true });
    } catch (error) {
      this.setState({
        trackingDetails: {
          shipmentsStatus: []
        },
        isTrackingApiError: true,
        spinnerHidden: true,
        trackingApiError: error.response && error.response.data
      });
    }
  }

  /**
   * Listens to the changes in the tracking number field and updates the state variable.
   * @param {*} event
   */
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ trackingNumber: event.target && event.target.value });
  }

  async componentDidMount() {
    // parse the query string to obtain any tracking numbers requested
    const queryParams: any = qs.parse(this.props.queryString.substring(1));

    // save the tracking number in the state variable so it is auto populated
    // in the text box
    await this.setStateSync({
      trackingNumber:
        queryParams.trackingNumbers || queryParams.trackingNumber || ""
    });

    // if tracking number is found, fetch details from the api
    if (this.state.trackingNumber) {
      this.setState({ spinnerHidden: false, isTrackingApiError: false });
      try {
        const trackingDetails = await tisProvider.getTrackInformation(
          this.state.trackingNumber
        );
        this.setState({
          trackingDetails,
          spinnerHidden: true
        });
      } catch (error) {
        await this.setStateSync({
          trackingDetails: {
            shipmentsStatus: []
          },
          isTrackingApiError: true,
          spinnerHidden: true,
          trackingApiError: error.response && error.response.data
        });
      }
    }
  }

  render() {
    let displayElement;
    if (this.state.isTrackingApiError) {
      displayElement = (
        <cc.Alert
          type="danger"
          message={this.state.trackingApiError.message}
          dismissible={false}
        />
      );
    } else {
      if (this.state.spinnerHidden) {
        displayElement = this.state.trackingDetails.shipmentsStatus.map(
          (status, i) => <TrackerOutput key={i} componentData={status} />
        );
      } else {
        displayElement = (
          <div className="container-fluid" style={{ textAlign: "center" }}>
            <div className="row" style={{ marginTop: "18px" }}>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <cc.Card>
                  <Spinner />
                </cc.Card>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <cc.Card>
                <form onSubmit={this.populateTrackingInformation}>
                  <cc.TextField
                    type="text"
                    id="trackingNumber"
                    value={this.state.trackingNumber}
                    onChange={this.handleChange}
                    // @ts-ignore
                    label={this.props.intl.formatMessage({
                      id: TrackerSearchElementId.track_textfield_placeholder
                    })}
                  />
                  <cc.Button
                    type="primary"
                    disabled={this.state.trackingNumber ? false : true}
                    style={{ float: "right" }}
                  >
                    <FormattedMessage
                      id={TrackerSearchElementId.track_button}
                    />
                  </cc.Button>
                </form>
              </cc.Card>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        {displayElement}
      </div>
    );
  }
}

export default injectIntl(TrackerSearchBar);
