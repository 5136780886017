import React from 'react';
import { countries } from 'country-data';
import {
  TrackerOutputProps,
  TrackingDetail,
  TrackingStatus,
  TrackerOutputTranslationElementId,
  Icon,
} from './TrackerOutputModels';
import * as cc from '@cimpress/react-components';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import {
  STATUS_TRANSLATION_ID,
  TrackingStatusExtendedDescriptionId,
  TrackingStatusTranslationId,
} from '../../models';
import { Address } from '../TrackerSearchBar/TrackerSearchBarModels';
import {v4 as uuid} from 'uuid';

const TrackerOutput = (props: TrackerOutputProps) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  });

  const formattedData = formatTrackingData(
    props.componentData.trackingDetails,
    props.componentData.trackingNumber,
    props.componentData.shippingAddress || {},
    props.componentData.carrierName! || '',
    props.componentData.estimatedDeliveryDate!,
    isTabletOrMobileDevice
  );

  return <>{formattedData}</>;
};

function getStepper(
  trackingDetails: TrackingDetail[],
  trackingNumber: string,
  address: Address,
  carrierName: string,
  estimatedDeliveryDate: string,
  isTabletOrMobileDevice: boolean
) {
  const headerDiv = getHeaderDiv(
    trackingNumber,
    address,
    carrierName,
    isTabletOrMobileDevice
  );
  const checkMarkIcon = Icon.checkMark;
  const weightsMap = new Map<TrackingStatus, number>([
    [TrackingStatus.READY_TO_DISPATCH, 0],
    [TrackingStatus.DISPATCHED, 1],
    [TrackingStatus.RECEIVED_BY_CARRIER, 2],
    [TrackingStatus.IN_TRANSIT, 3],
    [TrackingStatus.OUT_FOR_DELIVERY, 4],
    [TrackingStatus.DELIVERED, 5],
    [TrackingStatus.CANCELLED, 5],
  ]);

  const steps = [];

  const isLatestStatusAnException =
    trackingDetails[0].status === TrackingStatus.EXCEPTIONS;
  let activeStepIndex = -1;
  const stepperTextAlignment = isTabletOrMobileDevice ? 'left' : 'center';
  trackingDetails
    .filter(a => a.status !== TrackingStatus.EXCEPTIONS)
    .sort((a, b) => weightsMap.get(a.status)! - weightsMap.get(b.status)!)
    .forEach((detail, index) => {
      const isLastStatus = index === trackingDetails.length - 1;

      steps.push(
        <cc.Step
          key={uuid()}
          overrideBsStyle={'success'}
          icon={
            isLastStatus
              ? detail.status === TrackingStatus.DELIVERED
                ? checkMarkIcon
                : ''
              : checkMarkIcon
          }
        >
          <div style={{ textAlign: stepperTextAlignment }}>
            <div>
              <FormattedMessage id={STATUS_TRANSLATION_ID.get(detail.status)} />
            </div>
            <small>{convertUtcToLocalTime(detail.timestamp)}</small>
          </div>
        </cc.Step>
      );

      activeStepIndex++;
    });

  const isShipmentDelivered = trackingDetails.some(
    detail => detail.status === TrackingStatus.DELIVERED
  );

  if (isLatestStatusAnException) {
    steps.push(
      <cc.Step overrideBsStyle={'danger'} icon='' key={uuid()}>
        <div style={{ textAlign: stepperTextAlignment }}>
          <div>
            <FormattedMessage
              id={TrackingStatusExtendedDescriptionId.exception}
            />
          </div>
          <small>{convertUtcToLocalTime(trackingDetails[0].timestamp)}</small>
        </div>
      </cc.Step>
    );
    activeStepIndex++;
  }

  // If the shipment is not delivered and estimated delivery date is present
  // add the step `Estimated Delivery` with estimated delivery date
  // else add empty step `Delivered`
  if (!isShipmentDelivered) {
    const estimatedDelivery =
      (estimatedDeliveryDate && convertUtcToLocalTime(estimatedDeliveryDate)) ||
      '';
    steps.push(
      <cc.Step icon='' key={uuid()}>
        <div style={{ textAlign: stepperTextAlignment }}>
          <div>
            <FormattedMessage
              id={
                (estimatedDelivery &&
                  TrackerOutputTranslationElementId.estimated_delivery) ||
                TrackingStatusTranslationId.delivered
              }
            />
          </div>

          <small>{estimatedDelivery}</small>
        </div>
      </cc.Step>
    );
  }
  const stepsHeight = isTabletOrMobileDevice
    ? `${steps.length * 65}px`
    : '100px';

  return (
    <div className='container-fluid' style={{ marginTop: '18px' }}>
      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
          <cc.Card header={headerDiv}>
            <div style={{ height: stepsHeight }}>
              <cc.Stepper
                vertical={isTabletOrMobileDevice}
                activeStep={activeStepIndex}
              >
                {steps}
              </cc.Stepper>
            </div>
          </cc.Card>
          <div className='col-md-2'></div>
        </div>
      </div>
    </div>
  );
}

function getCard(
  translationElementId: string,
  trackingNumber: string,
  address: Address,
  carrierName: string,
  isTabletOrMobileDevice: boolean
) {
  const headerDiv = getHeaderDiv(
    trackingNumber,
    address,
    carrierName,
    isTabletOrMobileDevice
  );

  return (
    <div className='container-fluid'>
      <div className='row' style={{ marginTop: '18px' }}>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
          <cc.Card header={headerDiv}>
            <div style={{ textAlign: 'left' }}>
              <FormattedMessage id={translationElementId} />
            </div>
          </cc.Card>
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  );
}

function getHeaderDiv(
  trackingNumber: string,
  address: Address,
  carrierName: string,
  isTabletOrMobileDevice: boolean
) {
  const trackingNumberHeader = (
    <span>
      <FormattedMessage
        id={TrackerOutputTranslationElementId.card_tracking_number_header}
      />
      : <b>{trackingNumber}</b>
    </span>
  );

  const destination = formatAddress(address);
  
  const carrierNameHeader = (
    <span>
      <FormattedMessage id={TrackerOutputTranslationElementId.carrier_name} />:{" "}
      <b>{carrierName}</b>
    </span>
  );

  // TODO: We shall design our application differently in all cases
  // Mobile
  // Tablet
  // Website
  const deliverToHeader = (
    <span>
      <FormattedMessage id={TrackerOutputTranslationElementId.deliver_to} />:{' '}
      <b>{destination}</b>{' '}
      {(countries[address.countryCode!] &&
        countries[address.countryCode!].emoji) ||
        ''}
    </span>
  );

  if (destination.length) {
    return attachToolTip(
      carrierName,
      <div>
        <div className="col-md-4">{carrierNameHeader} </div>
        <div className="col-md-4">{trackingNumberHeader} </div>
        <div className="col-md-4">{deliverToHeader} </div>
      </div>
    );
  }

  return attachToolTip(carrierName, <div>{trackingNumberHeader}</div>);
}

function attachToolTip(content: string, component: JSX.Element) {
  // return (
  //   <cc.Tooltip direction='top' contents={content}>
  //     {component}
  //   </cc.Tooltip>
  // );

  return component;
}

// Format the address in `City, State, Country name` format
// Returns empty when country is missing.
function formatAddress(address: Address) {
  if (!address.countryCode) {
    return '';
  }

  let destination = [];

  if (address.locality) {
    destination.push(address.locality);
  }

  if (address.region) {
    destination.push(address.region);
  }

  destination.push(countries[address.countryCode!].name);

  return destination.join(', ');
}

function formatTrackingData(
  trackingDetails: TrackingDetail[],
  trackingNumber: string,
  address: Address,
  carrierName: string,
  estimatedDeliveryDate: string,
  isTabletOrMobileDevice: boolean
) {
  /*
    Cases:
      1. Tracking details with more than one element, display Stepper, with or without estimated delivery date.
      2. Tracking details with only one element & has estimated delivery date, display stepper.
      3. Tracking details with one element, display the single card
  */
  if (trackingDetails.length === 1 && !estimatedDeliveryDate) {
    return getSingleCard(
      trackingDetails,
      trackingNumber,
      address,
      carrierName,
      isTabletOrMobileDevice
    );
  }

  return getStepper(
    trackingDetails,
    trackingNumber,
    address,
    carrierName,
    estimatedDeliveryDate,
    isTabletOrMobileDevice
  );
}

function getSingleCard(
  trackingDetails: TrackingDetail[],
  trackingNumber: string,
  address: Address,
  carrierName: string,
  isTabletOrMobileDevice: boolean
) {
  const trackingDetail = trackingDetails[0];

  switch (trackingDetail.status) {
    case TrackingStatus.NOT_FOUND:
    case TrackingStatus.TO_BE_IGNORED:
    case TrackingStatus.CANCELLED:
      return getCard(
        TrackingStatusExtendedDescriptionId.not_found,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
    case TrackingStatus.READY_TO_DISPATCH:
      return getCard(
        TrackingStatusExtendedDescriptionId.prepared,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
    case TrackingStatus.DELIVERED:
      return getCard(
        TrackingStatusExtendedDescriptionId.delivered,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
    case TrackingStatus.EXCEPTIONS:
      return getCard(
        TrackingStatusExtendedDescriptionId.exception,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
    case TrackingStatus.IN_TRANSIT:
      return getCard(
        TrackingStatusExtendedDescriptionId.in_transit,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
    case TrackingStatus.OUT_FOR_DELIVERY:
      return getCard(
        TrackingStatusExtendedDescriptionId.out_for_delivery,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
    case TrackingStatus.RECEIVED_BY_CARRIER:
      return getCard(
        TrackingStatusExtendedDescriptionId.received_by_carrier,
        trackingNumber,
        address,
        carrierName,
        isTabletOrMobileDevice
      );
  }
}

/**
 * Converts UTC Timezone to Browser's Local timezone.
 * @param {string} utcTimestamp
 */
function convertUtcToLocalTime(utcTimestamp: string) {
  // Convert UTC time to local time
  let localTime = new Date(utcTimestamp);

  // Returns the browser locale
  // i.e. India > en-GB, US > en-US
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  // The time format adopted from the browser locale ,
  // eg: MM/DD/YYYY for US and DD/MM/YYYY for UK

  return Intl.DateTimeFormat(locale).format(localTime);
}

export default TrackerOutput;
