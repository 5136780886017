import { useState, useEffect } from "react";
/**
 * This Hook allows the web page to be resized when the window dimensions change.
 * It returns the dimensions of the window post the resize event
 */
export function GetWindowSize(): {
  width: number | undefined;
  height: number | undefined;
} {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
