import { TrackingStatus } from '../TrackerOutput/TrackerOutputModels';
import { WrappedComponentProps } from 'react-intl';

export interface TrackerSearchBarState {
  trackingNumber: string;
  trackingDetails: {
    shipmentsStatus: ShipmentsStatus[];
  };
  spinnerHidden: boolean;
  isTrackingApiError: boolean;
  trackingApiError: any;
}

export interface TrackerSearchBarProps extends WrappedComponentProps<'intl'> {
  queryString: string;
}

export interface ShipmentsStatus {
  trackingNumber: string;
  carrierName?: string;
  references?: string[];
  trackingDetails: TrackingDetail[];
  estimatedDeliveryDate?: string;
  shippingAddress?: Address;
}

export interface Address {
  locality?: string;
  region?: string;
  countryCode?: string;
}

export interface TrackingDetail {
  status: TrackingStatus;
  statusDescription?: string;
  timestamp: string;
}

export enum TrackerSearchElementId {
  track_button = 'button_track_title',
  track_textfield_placeholder = 'textfield_track_placeholder',
}
