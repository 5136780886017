/**
 * @fileoverview Stores German translations for component texts.
 * ISO Language Name: German
 * ISO 639-1 Language Code: de
 * Native Name: Deutsch
 */

import {
  TranslationMessage,
  TrackingStatusTranslationId,
  TrackingStatusExtendedDescriptionId,
} from '../../models';
import { FooterTranslationElementId } from '../../components/Footer/FooterModels';
import { TrackerSearchElementId } from '../../components/TrackerSearchBar/TrackerSearchBarModels';
import { TrackerOutputTranslationElementId } from '../../components/TrackerOutput/TrackerOutputModels';
import { NavbarTranslationElementId } from '../../components/Navbar/NavbarModels';

const deTranslations: TranslationMessage = {
  [NavbarTranslationElementId.header]: 'Sendungsverfolgung',
  [NavbarTranslationElementId.menu]: 'Speisekarte',
  [NavbarTranslationElementId.language]: 'Sprache',

  [TrackerSearchElementId.track_button]: 'Spur',
  [TrackerSearchElementId.track_textfield_placeholder]: `Sendungsnummer(n) eingeben (durch Komma getrennt)`,

  [TrackerOutputTranslationElementId.card_tracking_number_header]:
    'Sendungsnummer',
  [TrackerOutputTranslationElementId.carrier_name]: 'Verzonden Met',
  [TrackerOutputTranslationElementId.deliver_to]: 'Ausliefern',
  [TrackerOutputTranslationElementId.estimated_delivery]:
    'Voraussichtliche Lieferung',

  [FooterTranslationElementId.copyright_notice_message]:
    'Alle Rechte vorbehalten',
  [FooterTranslationElementId.timezone_notice_message]:
    'Alle angegebenen Daten und Zeiten sind',

  [TrackingStatusTranslationId.delivered]: 'Geliefert',
  [TrackingStatusTranslationId.dispatched]: 'Versandt',
  [TrackingStatusTranslationId.in_transit]: 'Im Transit',
  [TrackingStatusTranslationId.out_for_delivery]: 'Raus zur Lieferung',
  [TrackingStatusTranslationId.ready_to_dispatch]: 'Versandbereit',
  [TrackingStatusTranslationId.received_by_carrier]: 'Vom Spediteur erhalten',
  [TrackingStatusTranslationId.cancelled]: 'abgesagt',

  [TrackingStatusExtendedDescriptionId.delivered]: 'Sendung geliefert',
  [TrackingStatusExtendedDescriptionId.exception]: 'Etwas ist schief gelaufen!',
  [TrackingStatusExtendedDescriptionId.cancelled]: 'Die Sendung wird storniert',
  [TrackingStatusExtendedDescriptionId.in_transit]:
    'Der Spediteur liefert die Sendung gerade aus',
  [TrackingStatusExtendedDescriptionId.out_for_delivery]: 'Komme bald an!',
  [TrackingStatusExtendedDescriptionId.prepared]: 'Versand vorbereitet',
  [TrackingStatusExtendedDescriptionId.received_by_carrier]:
    'Versand beim Spediteur',
  [TrackingStatusExtendedDescriptionId.not_found]: 'Tracking-Nummer wurde nicht gefunden. Wenn Ihre Sendung heute erstellt wurde, versuchen Sie es bitte irgendwann noch einmal.',
};

export default deTranslations;
