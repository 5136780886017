import React from 'react'
import TrackerSearchBar from './TrackerSearchBar/TrackerSearchBar'
import Footer from './Footer/Footer'
import { useMediaQuery } from 'react-responsive'
import Navbar from './Navbar/Navbar'
import { GetWindowSize } from '../hooks/WindowSize'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import translations, { Languages } from '../translations/translations'

function App(props: { language: Languages }) {
  // @ts-ignore
  const translationMessages = translations.get(props.language) as Record<
    string,
    string
  >

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  })
  const navbarHeight = 70 // 70 is the number derived from the MCP css
  const footerHeight = 60 + (isTabletOrMobileDevice ? 22 : 0) // 60 is the number derived from the mcp css
  const calculatedBodyHeight = window.innerHeight - navbarHeight - footerHeight
  // No action required on the new dimensions therefore not saving the response to a variable
  GetWindowSize()

  return (
    <>
      <IntlProvider locale={props.language} messages={translationMessages}>
        <Navbar />
        <div style={{ height: calculatedBodyHeight, overflowY: 'auto' }}>
          <TrackerSearchBar queryString={window.location.search} />
        </div>
        <Footer />
      </IntlProvider>
    </>
  )
}

// state => state is a function that is mapping the state to props for App class
export default connect(state => state)(App)
