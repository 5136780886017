import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { FooterTranslationElementId } from './FooterModels';

function Footer() {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  });

  // Determining text alignment for small and big screens.
  // The first and second column have the text aligned to left and respectively for
  // large screens, or to the center otherwise.
  const col1TextAlign = isTabletOrMobileDevice ? 'center' : 'left';
  const col2TextAlign = isTabletOrMobileDevice ? 'center' : 'right';

  return (
    <footer
      style={{
        position: 'absolute',
        bottom: '0',
        width: '100%',
        marginTop: '0px',
      }}
    >
      <div className='container-fluid'>
        <div className='row'>
          <div
            className='col-md-6'
            style={{ marginTop: '8px', textAlign: col1TextAlign }}
          >
            ©{' '}
            <FormattedMessage
              id={FooterTranslationElementId.copyright_notice_message}
            />{' '}
            &nbsp;&nbsp; Release 1.0
          </div>
          <div
            className='col-md-6'
            style={{ marginTop: '8px', textAlign: col2TextAlign }}
          >
            <FormattedMessage
              id={FooterTranslationElementId.timezone_notice_message}
            />
            :{' '}
            <b>
              <span>{Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
            </b>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
