import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { NavbarTranslationElementId } from './NavbarModels'
import { PreferencesProps } from './NavbarModels'
import { connect } from 'react-redux'
import { Select } from '@cimpress/react-components'
import { getSupportedLanguages } from '../../translations/translations'

const Navbar = (props: PreferencesProps) => {
  function handleLanguageChange(event: any): void {
    const code = (event && event.value) || 'en'
    // Store the user preference in local storage.
    localStorage.setItem('language', code)
    // Dispatches the reducer function to change global state
    props.dispatch({ type: 'CHANGE_LANGUAGE', language: code })
  }

  let allLanguages = getSupportedLanguages().map(lang => ({
    label: lang.name,
    value: lang.value,
  }))

  let languageTitle = props.intl.formatMessage({
    id: NavbarTranslationElementId.language,
  })

  return (
    <div className='navbar-fluid'>
      <nav className='navbar navbar-block subtitle'>
        <div className='navbar-header'>
          <span
            className='navbar-toggle clickable'
            data-toggle='collapse'
            data-target='#navbar-fluid-collapse-1'
          >
            <FormattedMessage id={NavbarTranslationElementId.menu} />{' '}
            <span className='caret'></span>
          </span>
          <h2>
            <FormattedMessage id={NavbarTranslationElementId.header} />
          </h2>
        </div>
        <div
          className='fluid-collapse navbar-fluid-collapse navbar-right'
          id='navbar-fluid-collapse-1'
          // Style of the Language div
          style={{ height: '0px', width: '120px' }}
        >
          <ul className='nav nav-tabs'>
            <Select
              style={{
                borderStyle: 'none',
                cursor: 'pointer',
              }}
              placeholder={languageTitle}
              name='userLanguage'
              value={allLanguages.find(t => props.language === t.value)}
              options={allLanguages}
              onChange={e => handleLanguageChange(e)}
              // Remove the x mark from select component.
              clearRenderer={() => null}
            />
          </ul>
        </div>
      </nav>
    </div>
  )
}

// state => state is a function that is mapping the state to props for App class
export default connect(state => state)(injectIntl(Navbar))
